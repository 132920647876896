import React, { useState } from "react";
import "./Dropdown.scss";

function Dropdown({ options, onChange }) {
  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="dropdown-container">
      <select value={selectedOption} onChange={handleOptionChange}>
        <option value="">--</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Dropdown;
