export const NavBarMenuContent = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "The Team",
    url: "/team",
  },
  {
    title: "Past Events",
    url: "/past",
  },
  {
    title: "Dashboard",
    url: "/login",
  },
];
